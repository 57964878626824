<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-left-panel :data-item='dataItem'></project-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

                <Sidebar v-model:visible="visibleLeft" :baseZIndex="1000">
					<h3>Resmi Bilgiler</h3>

					<p class="text-lg">
                        Resmi bilgilerin tamamının girilmesi gerekmektedir.<br>
                        <br>
					</p>

				</Sidebar>

				</span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>
                <ProjectTabMenu :project-id='projectId'></ProjectTabMenu>
                <div class='col-12'>

                    <div class="field grid mb-5">
                        <label for="officialName" class="col-12 mb-2 md:col-2 md:mb-0">Firma Ünvanı<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <InputText id="officialName" v-model='dataItem.officialName' type="text" autocomplete='off'/>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label for="officialAddress" class="col-12 mb-2 md:col-2 md:mb-0">Fatura Adresi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <InputText id="officialAddress" v-model='dataItem.officialAddress' type="text" autocomplete='off'/>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label for="officialPostalCode" class="col-12 mb-2 md:col-2 md:mb-0">Posta Kodu<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <InputText id="officialPostalCode" v-model='dataItem.officialPostalCode' type="text" maxlength='5' autocomplete='off'/>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">İl<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <Dropdown id="city" v-model="dataItem.officialCityId" :options="cityList" optionLabel="name" option-value='id' placeholder="Seçim Yapın"  @change="cityChanged"></Dropdown>
                        </div>
                    </div>

                    <div class="field grid mb-5" v-if='dataItem.officialCityId > 0'>
                        <label class="col-12 mb-2 md:col-2 md:mb-0">İlçe<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <Dropdown id="town" v-model="dataItem.officialTownId" :options="townList" optionLabel="name" option-value='id' placeholder="Seçim Yapın"></Dropdown>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Vergi Dairesi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <InputText id="taxOffice" v-model='dataItem.taxOffice' type="text" autocomplete='off'/>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Vergi Numarası<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <InputText id="taxNumber" v-model='dataItem.taxNumber' type="text" autocomplete='off' maxlength='10'/>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">E-Fatura Adresi</label>
                        <div class="col-12 md:col-10">
                            <InputText id="eInvoiceAddress" v-model='dataItem.eInvoiceAddress' type="text" autocomplete='off'/>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Banka</label>
                        <div class="col-12 md:col-10">
                            <Dropdown id="banka" v-model="dataItem.bankName" :options="bankList" optionLabel="name" option-value='name' placeholder="Seçim Yapın"></Dropdown>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label for="iban" class="col-12 mb-2 md:col-2 md:mb-0">IBAN</label>
                        <div class="col-12 md:col-10">
                            <InputText id="iban" v-model='dataItem.iban' type="text" maxlength="26" autocomplete='off'/>
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' @click='save'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import ProjectService from '../../services/projectService';
import AdminToast from '@/components/shared/toast/adminToast';
import AddressService from '../../services/addressService';
import ProjectLeftPanel from './projectLeftPanel';
import ProjectTabMenu from './projectTabMenu';
import { getAllBanks, getProject } from '../common/commonFunctions';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';

export default {
    components: { ProjectTabMenu, ProjectLeftPanel, AdminToast },
    _projectService: null,
    _addressService: null,
    _bankService: null,

    created() {
        this._addressService = new AddressService();
        this._projectService = new ProjectService();
        this.projectId = this.$route.params.projectId;
    },
    async mounted() {
        checkActiveProjectIdIsValid(this, this.projectId);
        this.bankList = await getAllBanks();
        this.dataItem = await getProject(this.projectId);
        await this.fillDropDowns(this.dataItem.officialCityId);
    },
    data() {
        return {
            projectId: 0,
            dataItem: {
                name: '',
                isActive: true,
                projectLogo:"",
                officialName:"",
                officialAddress:"",
                officialPostalCode:"",
                officialCityId:0,
                officialTownId:0,
                taxNumber:"",
                taxOffice:"",
                eInvoiceAddress:"",
                bankName:"",
                iban:""
            },
            bankList: [],
            cityList: [],
            townList:[],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._projectService.updateProjectOfficial(this.projectId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, "Resmi Bilgiler Güncelleme Başarılı");
                } else {
                    showErrorMessage(this, 'Resmi Bilgileri Güncellemede Sorun İle Karşılaşıldı');
                }
            }
        },
        validateForm() {
            if (this.dataItem.officialName == null || this.dataItem.officialName == '') {
                showValidationMessage(this, 'Firma Ünvanı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.officialAddress == null || this.dataItem.officialAddress == '') {
                showValidationMessage(this, 'Fatura Adresi Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.officialPostalCode == null || this.dataItem.officialPostalCode == '') {
                showValidationMessage(this, 'Fatura Posta Kodu alanı boş bırakılamaz');
                return false;
            }

            if (this.dataItem.officialCityId == null || this.dataItem.officialCityId == 0 || this.dataItem.officialCityId == '') {
                showValidationMessage(this, 'İl Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.officialTownId == null || this.dataItem.officialTownId == 0 || this.dataItem.officialTownId == '') {
                showValidationMessage(this, 'İlçe Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.taxOffice == null || this.dataItem.taxOffice == '') {
                showValidationMessage(this, 'Vergi Dairesi Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.taxNumber == null || this.dataItem.taxNumber == '') {
                showValidationMessage(this, 'Vergi Numarası Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },
        async fillDropDowns(cityId) {
            let cityResponse = await this._addressService.getCities(90);
            if(cityResponse.isSuccess)
            {
                this.cityList = cityResponse.data;
            }
            if(cityId != null && cityId >0)
            {
                let townResponse = await this._addressService.getTowns(cityId);
                if(townResponse.isSuccess)
                {
                    this.townList = townResponse.data;
                }
            }

        },
        async cityChanged(event) {
            let townResponse = await this._addressService.getTowns(event.value);
            if (townResponse.isSuccess) {
                this.townList = townResponse.data;
            }
        }
    },
};

</script>


<style scoped>

</style>
